import { React, useState } from "react";
import QRScanner from "./QRScanner";
// import CryptoJS from "crypto-js";
// import "./App.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Navbar from "./Navbar";

const Checkin = () => {
  const [code, setCode] = useState("");
  const [devfolioUserName, setDevfolioUserName] = useState("");
  // const [email, setEmail] = useState("");

  const handleScan = (value, checkinViaTextBox = false) => {
    if (checkinViaTextBox) {
      value = "https://devfolio.co/@" + value;
    } else {
      value = "https://devfolio.co/@" + JSON.parse(value)?.username;
    }
    setCode(value);
    console.log(value);
    toast.promise(
      axios
        .post("https://hackaccino-tracker-backend.vercel.app/api/checkin", {
          devfolioUrl: value,
        })
        .then((res) => {
          console.log(res);
          window.open(
            `https://org.devfolio.co/hackaccino/checkins?username=${value.split("@").pop()}`,
            "_blank"
          );
          return "Check-in successful!";
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject(
            err?.response?.data?.message || "An error occurred!"
          );
        }),
      {
        loading: `Checking in ${value.split("@").pop()}`,
        success: (msg) => msg,
        error: (err) => err,
      }
    );
  };

  const sendMail = (email) => {
    axios
      .post("https://hackaccino-tracker-backend.vercel.app/api/sendmail", {
        email: email,
      })
      .then((res) => {
        console.log(res);
        toast.success("Email sent successfully!");
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.message || "An error occurred!");
      });
  };

  return (
    <div>
      <Toaster />
      <Navbar />
      {/* Scanner */}
      <h2
        style={{
          textAlign: "center",
          color: "white",
        }}
      >
        Scan QR Code
      </h2>
      <QRScanner onScan={handleScan} />

      {/* Manual Checkin */}
      <h2
        style={{
          textAlign: "center",
          color: "white",
        }}
      >
        CheckIn Manually
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <p>devfolio.co/@</p>
          <input
            placeholder="username"
            style={{
              padding: "10px",
              marginRight: "10px",
              color: "white",
              borderRadius: "5px",
              backgroundColor: "#ffffff10",
            }}
            type="text"
            value={devfolioUserName}
            onChange={(e) => setDevfolioUserName(e.target.value)}
          />

          <button
            style={{
              padding: "12px 30px",
              borderRadius: "5px",
              backgroundColor: "#ffffff10",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => handleScan(devfolioUserName, true)}
          >
            Submit
          </button>
        </div>
      </div>
      <br />
      <br />

      {/* Send Email */}
      {/* <h2
        style={{
          textAlign: "center",
          color: "white",
        }}
      >
        Explicitly Mail QR
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <p>Participant's Email</p>
          <input
            placeholder="mail@example.com"
            style={{
              padding: "10px",
              marginRight: "10px",
              color: "white",
              borderRadius: "5px",
              backgroundColor: "#ffffff10",
            }}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            style={{
              padding: "12px 30px",
              borderRadius: "5px",
              backgroundColor: "#ffffff10",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              sendMail(email);
            }}
          >
            Send
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Checkin;
