import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Navbar from "./Navbar";

const Users = () => {
  const [users, setUsers] = useState([]);

  const sendMail = (email) => {
    axios
      .post("https://hackaccino-tracker-backend.vercel.app/api/sendmail", {
        email: email,
      })
      .then((res) => {
        console.log(res);
        toast.success("Email sent successfully!");
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.message || "An error occurred!");
      });
  };

  const getUsers = async () => {
    axios
      .get("https://hackaccino-tracker-backend.vercel.app/api/users")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("email")) {
      getUsers();
    } else {
      window.location.href = "/";
    }
  }, []);

  return (
    <div>
      <Navbar />
      {users.map((user, index) => (
        <div
          key={user.id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            margin: "10px",
            borderRadius: "5px",
            padding: "5px 20px",
            border: "1px solid #f0f0f020",
          }}
        >
          {/* <p>{user.firstName}</p> */}
          <p>{index + 1}. @{user.devfolio.split("@").pop()}</p>
          <button
            style={{
              opacity: user.emailSent ? 0.1 : 1,
            }}
            onClick={() => !user.emailSent && sendMail(user.email)}
          >
            Send Mail
          </button>
        </div>
      ))}
      <p
        onClick={() => {
          localStorage.clear();
          window.location.href = "/";
        }}
        style={{
          textAlign: "center",
          cursor: "pointer",
          color: "#5ad",
          fontWeight: "bold",
          fontSize: "14px",
          marginTop: "20px",
        }}
      >
        Logout?
      </p>
    </div>
  );
};

export default Users;
