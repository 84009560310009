import "./App.css";
import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Checkin from "./components/Checkin";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const clientId =
  "424959506353-u5v36sk258n7qin8uf0kfoqs2a9dnjqj.apps.googleusercontent.com";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [status, setStatus] = useState("");
  const accessSheetUrl =
    "https://sheets.googleapis.com/v4/spreadsheets/1fqSiUgvNt8KFMLTnnZA-uP6MbhuIVhrwYbgiZPEq_xM/values/Access!A:B?key=AIzaSyC3RozK2ZUVM9HbyhWSZnNWBZsatXFpVNI";

  const authorizeUser = async (user) => {
    axios
      .get(accessSheetUrl)
      .then((res) => {
        formatData(res?.data?.values).forEach((row) => {
          if (row?.Email === user?.email) {
            setIsLoggedIn(true);
            localStorage.setItem("token", user.credential);
            localStorage.setItem("email", user.email);
            localStorage.setItem("name", user.name);
            toast.success("Login successful!");
            return;
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function formatData(data) {
    const header = data[0];
    const rows = data.slice(1);
    const formattedData = [];
    for (const row of rows) {
      const rowObj = {};
      for (let i = 0; i < row.length; i++) {
        const key = header[i];
        rowObj[key] = row[i];
      }
      formattedData.push(rowObj);
    }

    return formattedData;
  }

  const handleLogin = (credentialResponse) => {
    var resp = jwtDecode(credentialResponse.credential);
    authorizeUser(resp);
  };

  useEffect(() => {
    localStorage.getItem("email") && setIsLoggedIn(true);
  }, []);

  return (
    <div>
      <Toaster />
      {isLoggedIn ? (
        <Checkin />
      ) : (
        <div className="login-container">
          <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin
              onSuccess={handleLogin}
              onError={() => {
                setStatus("Error: could not log you in...");
              }}
              useOneTap
            />
            <p className="status">{status}</p>
          </GoogleOAuthProvider>
        </div>
      )}
    </div>
  );
}

export default App;
