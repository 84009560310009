import React from 'react';

const Navbar = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#f0f0f010',
      borderRadius: '5px',
      margin: '10px',
    }}>
      <nav>
        <ul style={{
          display: 'flex',
          gap: '10px',
          listStyle: 'none',
          margin: '0',
          padding: '5px 15px',
        }}>
          <li style={{
            borderRadius: '5px',
          }}>
            <a href="/" style={{ textDecoration: 'none', color: '#fff' }}>Checkin</a>
          </li>
          <li style={{
            borderRadius: '5px',
          }}>
            <a href="/status" style={{ textDecoration: 'none', color: '#fff' }}>Scan</a>
          </li>
          <li style={{
            borderRadius: '5px',
          }}>
            <a href="/users" style={{ textDecoration: 'none', color: '#fff' }}>Users</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
