import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import QRScanner from "./QRScanner";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Status = () => {
  const [email, setEmail] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("Food");

  const handleScan = (email) => {
    setEmail(email);
    toast.promise(
      axios
        .post("https://hackaccino-tracker-backend.vercel.app/api/scan", {
          email: email,
          type: selectedType?.toLowerCase(),
          updatedByEmail: localStorage.getItem("email"),
        })
        .then((res) => {
          console.log(res);
          return `${selectedType} status updated`;
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject(
            err?.response?.data?.message || "An error occurred!"
          );
        }),
      {
        loading: `In Progress`,
        success: (msg) => msg,
        error: (err) => err,
      }
    );
    console.log(email);
  };

  useEffect(() => {
    if (!localStorage.getItem("email")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div>
      <Toaster />
      <Navbar />
      <h2
        style={{
          textAlign: "center",
          color: "white",
        }}
      >
        Scan for Food/Coffee
      </h2>
      <select
        style={{
          padding: "5px",
          margin: "10px",
          borderRadius: "5px",
          backgroundColor: "#f0f0f020",
          color: "#fff",
          // center
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        value={selectedType}
        onChange={(e) => setSelectedType(e.target.value)}
      >
        {/*  
          <option value="Snacks">Samosa</option>
          <option value="Coffee">Beanly Coffee</option>
         */}
        <option value="Food">BreakFast</option>
      </select>
      <QRScanner onScan={handleScan} />
    </div>
  );
};

export default Status;
